import Uploader from '../handler/Uploader';
import DragAndDrop from './DragAndDrop';
import FileManagerButton from './FileManagerButton';
import FileManagerModal from './FileManagerModal';
import UserFileList from './UserFileList';
import UploadButton from './UploadButton';

export const components = {
  DragAndDrop,
  FileManagerButton,
  FileManagerModal,
  UserFileList,
  Uploader,
  UploadButton,
};
