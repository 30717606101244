export default class QualityState {
    name: string;
    url: string;
    type: string;

    constructor() {
        this.name = "";
        this.url = "";
        this.type = "normal";
    }
}
