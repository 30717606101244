export const playerData = {
    url: "https://cdnjs.cloudflare.com/ajax/libs/dplayer/1.26.0/DPlayer.min.js",
    loaded: false,
};

export const extensions = [
    {
        attributeName: "embedVideoDash",
        url: "https://cdnjs.cloudflare.com/ajax/libs/dashjs/4.4.1/dash.all.min.js",
        loaded: false,
    },
    {
        attributeName: "embedVideoFlv",
        url: "https://cdnjs.cloudflare.com/ajax/libs/flv.js/1.6.2/flv.min.js",
        loaded: false,
    },
    {
        attributeName: "embedVideoHls",
        url: "https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.2.0/hls.min.js",
        loaded: false,
    },
    {
        attributeName: "embedVideoShaka",
        url: "https://cdnjs.cloudflare.com/ajax/libs/mux.js/6.2.0/mux.min.js",
        loaded: false,
    },
    {
        attributeName: "embedVideoShaka",
        url: "https://cdnjs.cloudflare.com/ajax/libs/shaka-player/4.1.2/shaka-player.compiled.js",
        loaded: false,
    },
    {
        attributeName: "embedVideoWebTorrent",
        url: "https://cdnjs.cloudflare.com/ajax/libs/webtorrent/1.8.26/webtorrent.min.js",
        loaded: false,
    },
];
